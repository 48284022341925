<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Jalousien / Vertikaljalousien <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Germania - Vertikaljalousien
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full row-span-4 lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full lg:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Elegante Schlichtheit für größere Fenster
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Hervorragend geeignet für Fensterfronten, Schiebetüren oder
            spezielle Fensterformen. Luxaflex® Vertikal-Lamellen gewährleisten
            eine optimale Lichtregulierung und besten Sicht-schutz in jedem Raum
            Ihres Hauses. Die Vertikal-Kollektion enthält eine große Auswahl an
            Stoff-Designs, unterschiedlichen Transparenzen und diversen
            Texturen. Ob für klassisch oder modern eingerichtete Räume – die
            eleganten und stilvollen Stoffe harmonieren mit jedem Interieur.
          </p>
        </div>
        <div class="col-span-full lg:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Beeindruckende Stoffauswahl
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Die exklusive Kollektion ist reich an Farben, Mustern und
            raffinierten Texturen. Genießen Sie die Vielfalt an Effekten und
            Stilen für Ihr Interieur und setzen Sie Ihren Wunsch nach perfekter
            Balance von Lichtsteuerung, Sicht-schutz und eigenem Stil um.
          </p>
        </div>
        <div class="col-span-full lg:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Perfekt für jedes Zimmer
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Vertikal-Lamellen sind nicht nur wunderbar elegant, sondern auch
            überaus praktisch in Räumen mit hoher Luftfeuchtigkeit. Viele der
            Stoffe sind feuchtraumgeeignet.
          </p>
        </div>
      </div>
      <div class="p-4">
        <div class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</div>
        <tabs :mode="mode">
          <tab title="Powerview">
            <p class="text-lg">
              <span class="font-bold">Motorisierung</span> <br />
              Was wäre, wenn Ihr Sonnenschutz Gedanken lesen könnte? Wenn er
              wüsste, wie Ihr Tag aussehen wird, und wenn er in der Lage wäre,
              ihn noch schöner werden zu lassen ? Dafür gibt es die PowerView®
              Motorisierung – ein kabelloses Motorisierungssystem, mit dem Sie
              alle Sonnenschutz-Produkte per Smartphone, Tablet oder
              Fernsteuerung bedienen können. Die Einbindung in ein Smart
              Home-System ist kein Problem.
            </p>
          </tab>
          <tab title="Kindersicherheit">
            <p class="text-lg">
              <span class="font-bold">Entwickelt mit Blick auf Sicherheit</span>
              <br />
              Kindersicherheit hat oberste Priorität. Von schnurlosen Systemen
              bis hin zu voll motorisierten Alternativen bietet Luxaflex® viele
              innovative Bediensysteme, die auf Sicherheit, Komfort und
              Schönheit ausgelegt sind. Besuchen Sie Ihren regionalen Luxaflex®-
              Fachhändler, um sich über die bestmögliche Bedienung für Ihre
              Einbau-situation zu informieren.
            </p>
          </tab>
          <tab title="Extras">
            <p class="text-lg">
              <span class="font-bold">Extras</span> <br />
              Germania - Vertikaljalousien ist in verschiedenen Farben und
              Ausführungen erhältich. <br />
              Besuchen Sie unsere Ausstellung oder lassen Sie sich beraten.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/germania/vertikaljalousien/vertikal-1.webp"),
          alt: "germania vertikaljalousien",
        },
        {
          image: require("@/assets/img/germania/vertikaljalousien/vertikal-12.webp"),
          alt: "vertikaljalousie hellblau weiss",
        },
        {
          image: require("@/assets/img/germania/vertikaljalousien/vertikal-11.webp"),
          alt: "vertikaljalousie weiss",
        },
        {
          image: require("@/assets/img/germania/vertikaljalousien/vertikal-4.webp"),
          alt: "vertikaljalousie hellblau",
        },
        {
          image: require("@/assets/img/germania/vertikaljalousien/vertikal-5.webp"),
          alt: "vertikaljalousie weiss",
        },
        {
          image: require("@/assets/img/germania/vertikaljalousien/vertikal-15.webp"),
          alt: "vertikaljalousie blumenmuster",
        },
      ],
    };
  },
};
</script>
